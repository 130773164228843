import './style.css'
import Phaser from 'phaser'

const sizes = {
  width: 400,
  height: 800
}
const speedDown = 100;

const gameStartDiv = document.querySelector("#gameStartDiv");
const gameEndDiv = document.querySelector("#gameEndDiv");
const startButton = document.querySelector("#startButton");

class BasicButton extends Phaser.GameObjects.Sprite {
  constructor(config) {

    //check if config contains a scene
    if (!config.scene) {
      console.log('missing scene');
      return;
    }
    //check if config contains a key
    if (!config.key) {
      console.log("missing key!");
      return;
    }
    //if there is no up property assume 0
    if (!config.up) {
      config.up = 0;
    }
    //if there is no down in config use up
    if (!config.down) {
      config.down = config.up;
    }
    //if there is no over in config use up
    if (!config.over) {
      config.over = config.up;
    }
    //call the constructor of the parent
    //set at 0,0 in case there is no x and y
    //in the config
    super(config.scene, 0, 0, config.key, config.up);

    //make a class level reference to the config
    this.config = config;


    //if there is an x assign it
    if (config.x) {
      this.x = config.x;
    }
    //if there is an x assign it
    if (config.y) {
      this.y = config.y;
    }

    if (config.width && config.height) {
      this.setDisplaySize(config.width, config.height);
    }
    //
    //add this to the scene
    config.scene.add.existing(this);
    //
    //make interactive and set listeners
    this.setInteractive();
    this.on('pointerdown', this.onDown, this);
    this.on('pointerup', this.onUp, this);
    this.on('pointerover', this.onOver, this);
    this.on('pointerout', this.onUp, this);
  }
  onDown() {
    this.setFrame(this.config.down);
  }
  onOver() {
    this.setFrame(this.config.over);
  }
  onUp() {
    this.setFrame(this.config.up);
  }
}

class GameScene extends Phaser.Scene {

  constructor() {
    super("scene-game");
    this.player
    this.cursor
    this.playerSpeed = speedDown + 50
    this.points = 0;
    this.textScore;
    this.textTime;
    this.timedEvent;
    this.remainingTime;
    this.dropSound;
    this.emitter;
    this.button;
    this.buttonBack;
    this.bitcoin;


  }
  preload() {
    this.load.image("back", "/assets/back.png");
    this.load.image("test", "/assets/dog.png");
    this.load.image("ball", "/assets/ball.png");
    this.load.image("bitcoin", "/assets/bitcoin.png");
    this.load.image("backbutton", "/assets/buttonback.png");
    this.load.audio("drop", "/assets/sounds/drop.wav");
  }
  create() {
    this.dropSound = this.sound.add("drop");
    this.add.image(0, 0, "back").setOrigin(0.25, 0.25);
    this.player = this.physics.add.image(sizes.width / 2 - 100, sizes.height / 2 - 100, "test").setOrigin(0, 0);
    this.player.setInteractive();
    this.player.on('pointerdown', this.onDown, this);

    this.player.setDisplaySize(200, 200).setOffset(0, 0);
    this.player.setImmovable = true;
    this.player.body.allowGravity = false;
    this.player.setCollideWorldBounds(true);

    this.cursor = this.input.keyboard.createCursorKeys();

    this.buttonBack = this.add.image(110, 110, "backbutton").setDisplaySize(200, 90);
    this.bitcoin = this.physics.add.image(50, 110, "bitcoin").setDisplaySize(60, 60);
    this.bitcoin.setImmovable = true;
    this.bitcoin.body.allowGravity = false;


    this.textScore = this.add.text(80, 100, this.points, { font: "25px arial", fill: "#ffffff" });
    this.textTime = this.add.text(10, sizes.height - 20, "TimeLeft:", { font: "25px arial", fill: "#FFFFFF" })

    this.timedEvent = this.time.delayedCall(60000, this.gameOver, [], this);
    this.emitter = this.add.particles(100, 100, "bitcoin",
      {
        speed: 200,
        gravity: speedDown - 100,
        scale: { start: 0.02, end: 0 }, end: 0,
        duration: 200,
        lifespan: 1200,
        emitting: false,
        follow: this.player,

      })

    this.button = new BasicButton({
      'scene': this,
      'key': 'bitcoin',
      'up': 0,
      'over': 0,
      'down': 0,
      'x': sizes.width / 4 - 20,
      'y': sizes.height - sizes.height / 9,
      'width': 100,
      'height': 100
    });

  }
  update() {
    this.remainingTime = Math.round(this.timedEvent.getRemainingSeconds());
    this.textTime.setText(this.remainingTime);
    const { left, right } = this.cursor
    if (left.isDown) {
      this.player.setVelocityX(-this.playerSpeed - 50);
    }
    else if (right.isDown) {
      this.player.setVelocityX(this.playerSpeed + 50);
    }
    else {
      this.player.setVelocityX(0);
    }
    this.textScore.setText(this.points);

  }


  onDown(sprite, pointer) {
    this.points++;

    this.emitter.start();

  }

  targetHit() {
    this.enemy.x = this.getRandomX();
    this.enemy.y = 0;
    this.points++;
    console.log(this.points);
    this.textScore.setText(this.points);
    this.dropSound.play();
    this.emitter.start();
  }

  getRandomX() {
    return Math.floor(Math.random() * sizes.width);
  }

  gameOver() {
    console.log("Game over!");
  }
}

const Config = {
  type: Phaser.WEBGL,
  width: sizes.width,
  height: sizes.height,
  canvas: gameCanvas,
  physics: {
    default: "arcade",
    arcade: {
      gravity: {
        y: speedDown
      },
      debug: false
    }
  },
  scene: [GameScene]
}

const game = new Phaser.Game(Config);

startButton.addEventListener("click", () => {
  gameStartDiv.style.display = "none"
  game.scene.resume("scene-game")
})


